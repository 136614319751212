import React, { Component } from 'react';
import GridItem from './gridItem';
import GridContent from './gridContent';
import { GridAnimation,ListScroll } from './gridAnimation';
import animation from "../../static/animations/toggle.json"
import lottie from "lottie-web"
import swipeAnimation from "../../static/animations/swipe.json"
import Animation from "../../components/animation";


class Grid extends Component {
    constructor(props) {
        super(props);
        // reference to the DOM node
        this.myElement = null;
        this.gridItems = [];
        //this.tl = new TimelineLite({ paused: true });

    }
    state = { showGrid: false,
        width: 0 }
   

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        this.setState({ width: window.outerWidth, widthInner: window.innerWidth });
    };

    toggleView = () => {
        this.setState(prevState => ({
            showGrid: ! this.state.showGrid
        }))
        setTimeout(()=>{
            window.msnry && window.msnry.layout();
        },200)

        
        if (this._animation) {
            var dir = 1
            console.log("START ANI in DIRection",dir)

            if (this.state.showGrid) {
                dir=-1;
            }
            this._animation.setDirection(dir);
            this._animation.play();
        }
        
    }
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions);
        /*this.tl.staggerTo(this.gridItems, 0.5, { autoAlpha: .5, y: -20 }, 0.1);
        TweenMax.staggerFrom('.box', 1.5, { y: 120, autoAlpha: 1,repeat:-1 }, 0.3);*/

        if (this._el) {
            this._animation = lottie.loadAnimation({
                container: this._el,
                renderer: "svg",
                animationData: animation,
                loop: false,
                autoplay: false
            })
        }
        GridAnimation(this.gridItems,this._el);
        if(!this.props.onlyGrid)
        {
            ListScroll();
        }

    }
    /*shouldComponentUpdate() {
        return false;
    }*/
    render() {
        //const data = this.props.data.slice(0,12)
        const { data } = this.props
        const { showGrid,width } = this.state;
        
        //const WIDTH = showGrid ? 1200 : data.length * 279;
        /*const WIDTH = data.length * 280;
        const OVERFLOW = WIDTH - 1440;
        const TRACKWIDTH = Math.round(WIDTH + (OVERFLOW / 2));*/

        const ITEMWIDTH = 280;
        const WIDTH = data.length * ITEMWIDTH;
        const OVERFLOW = Math.round(WIDTH - (width));
        const TRACKWIDTH = Math.round((WIDTH*2)-width)//Math.round(WIDTH + (OVERFLOW ));
        const TRACKOFFSET = Math.round((TRACKWIDTH / 2) * -1);//Math.round(((TRACKWIDTH/2)-(width/2)) * -1) - (1260/2);//Math.round((TRACKWIDTH - width) / - 3);
        //const TRACKOFFSET = Math.round((width / 2 - (TRACKWIDTH / 2)));
        //console.log('TRACKOFFSET', TRACKOFFSET);
        const OFFSET = OVERFLOW;
        /*let rendererProps = {
            active: this.props.href === window.location.pathname
        }*/
        //this.tl.kill().clear().pause(0);
        return (
            <>
                {/*<GridContentContext.Consumer>
                    {({ data, setData }) => (
                        setData(this.props.data)
                    )}
                    </GridContentContext.Consumer>*/}

            <div className="container">
            {! this.props.onlyGrid &&
                    <div
                        className={`grid-toggle`} onClick={this.toggleView}
                        id="animation"
                        ref={el => (this._el = el)}
                        style={{
                            position: "relative"
                        }}
                    />}
                    {/*<button
                        className="btn gsap-btn"
                        onClick={() => this.tl.play()}
                    >Play</button>*/}
            </div>
                {/*<div className="grid-outer">*/}
                <div style={{ marginLeft:TRACKOFFSET+'px', width: TRACKWIDTH + 'px' }} className={`grid-slider__track ${this.props.onlyGrid ? 'non-scrollable' : 'scrollable'}`} >
                    <div style={{
                        maxWidth: WIDTH + 'px', width: WIDTH + 'px'
                    }} className={`grid-wrap ${this.props.onlyGrid && 'fixed'}`}>
                        <div ref={div => this.myElement = div} className={`grid`}>
                            {data &&
                                data.map((item,index) => (
                                    <a href="#"
                                    key={item.node.uid}
                                     ref={div => this.gridItems[index] = div}
                                    className="grid__item">
                                        <GridItem data={item} />
                                    </a>
                                   
                                ))}
                    </div>
                </div>
                    {/*</div>*/}
            </div>
            {
                (! showGrid && !this.props.onlyGrid) &&
                <Animation speed={2} playOnce={true} className="swipeAnimation" animation={swipeAnimation} loop={false} />
            }
               
            {<GridContent data={data}></GridContent>}
            </>
        );
    }
}

export { Grid};

/*
left: OVERFLOW +'px',transform: 'translateX('+OVERFLOW+'px) translateZ(0px)',
*/