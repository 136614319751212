import React, { PureComponent } from 'react';

function getHostName(url) {
    var match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
    if (match != null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
        return match[2];
    }
    else {
        return null;
    }
}

class GridContent extends PureComponent {
    render() {
        const {data} = this.props;
       
        return (
             <>
                {/*<GridContentContext.Consumer>
                    {({ data, setData }) => (
                    */}
            <div className="grid-content">
                {data &&
                    data.map(item => (
                        <div key={item.node.uid} className="grid-content__item">
                            <div className="grid-content__item-intro">
                                <img className="grid-content__item-img" alt={item.node.data.alt || item.node.data.title.text}
                                    src={item.node.data.logo.url} />
                                <h2 className="grid-content__item-title">{item.node.data.title.text.replace(/&nbsp;/g, ' ')}</h2>
                            </div>
                            <h3 className="grid-content__item-subtitle">{item.node.data.position && item.node.data.position.text}</h3>
                           
                            <div className="grid-content__item-text">
                                <div className="paragraph" dangerouslySetInnerHTML={{
                                    __html: item.node.data.content.html,
                                }} />
                                {(item.node.data.url && item.node.data.url.url) &&
                                    <a className="grid-content__item-link" target="_blank" rel="follow" href={item.node.data.url.url}>{getHostName(item.node.data.url.url)}</a>
                                }
                            </div>
                        </div>
                    ))}
                
                <button className="grid-content__close">
                            <div dangerouslySetInnerHTML={{
                            __html: `<svg class="svg-icon" viewBox="0 0 20 20">
							<path fill="none" d="M11.469,10l7.08-7.08c0.406-0.406,0.406-1.064,0-1.469c-0.406-0.406-1.063-0.406-1.469,0L10,8.53l-7.081-7.08
							c-0.406-0.406-1.064-0.406-1.469,0c-0.406,0.406-0.406,1.063,0,1.469L8.531,10L1.45,17.081c-0.406,0.406-0.406,1.064,0,1.469
							c0.203,0.203,0.469,0.304,0.735,0.304c0.266,0,0.531-0.101,0.735-0.304L10,11.469l7.08,7.081c0.203,0.203,0.469,0.304,0.735,0.304
							c0.267,0,0.532-0.101,0.735-0.304c0.406-0.406,0.406-1.064,0-1.469L11.469,10z"></path>
						</svg>`}} />
                    Schließen
                </button>
                    {/*<svg className="grid-content__indicator icon icon--caret">Caret Indicator</svg > */}
			</div >
                    
                {/*</GridContentContext.Consumer>*/}
                </>
        );
    }
}

export default GridContent;