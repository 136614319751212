import React, { Component } from 'react';

const createMarkup = () => { return { __html: '<a href="#" class="grid__item"><div class="grid__item-bg" data-color="#c0d037"></div><div class="grid__item-wrap"><img class="grid__item-img" alt="Anvajo" src="https://brueckenkoepfede.cdn.prismic.io/brueckenkoepfede/6c44c943036672b583311c6a8784db1c6b29e523_anvajo_orginal.png"></div><h3 class="grid__item-title">Anvajo</h3></a>' }; };


class GridItem extends Component {
    shouldComponentUpdate(){
        return false;
    }
    
    render() {
        const item = this.props.data;
        return (
            <>
                <div ref="grid__item-bg" className="grid__item-bg" data-color={`${item.node.data.color && item.node.data.color}`}></div>
                <div className="grid__item-wrap" >
                    <img ref="grid__item-img" className="grid__item-img" alt={item.node.data.alt || item.node.data.title.text}
                        src={item.node.data.logo.url} />
                </div>
                <div className="grid__item-title"><span className="name">{item.node.data.title.text}</span> <span className="position">{item.node.data.position && item.node.data.position.text}</span></div>
            </>
        );
    }
}

export default GridItem;

/*
<a href="#" className="grid__item">
                <div ref="grid__item-bg" className="grid__item-bg" data-color={`${item.node.data.color && item.node.data.color}`}></div>
               <div className="grid__item-wrap" >

<img ref="grid__item-img" className="grid__item-img" alt={item.node.data.alt || item.node.data.title.text}
                        src={item.node.data.logo.url} />
                </div>
                <h3 className="grid__item-title">{item.node.data.title.text}</h3>
            </a>

*/

/*
<div dangerouslySetInnerHTML={createMarkup()} />
*/